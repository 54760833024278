
import {computed, defineComponent, onMounted} from "vue";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {onUpdated} from "@vue/runtime-core";
import {IPolicy} from "@/core/entity/IPolicy";
import store from "@/store";
import ClaimForm from "@/views/claim/ClaimForm.vue";
import router from "@/router";
import {ModelAddress} from "@/core/entity/IAddress";

export default defineComponent({
  name: "CreateClaim",
  components: {ClaimForm},
  props: {
    insuredId: {type: String}
  },
  setup() {
    const policy = computed<IPolicy>(() => store.state.PolicyModule.policy)
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Add Claim',
        [
          {link: true, router: '/policies', text: 'Search Policy'},
          {link: true, router: '/policies/' + policy?.value?.id, text: 'Policy View'},
          {link: false, router: '', text: 'Add Claim'}
        ]
      )
    })
    const createdClaim = (claim) => {
      router.push({
        name: "policyClaims",
        params: {id: claim.policy.id}
      });
    }
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('New Claim',
        [
          {link: true, router: '/policies', text: 'Search Policy'},
          {link: true, router: '/policies/' + policy?.value?.id, text: 'Policy View'},
          {link: false, router: '', text: 'Add Claim'}
        ]
      )
    })
    return {
      ModelAddress,
      ...LoadEntity(Actions.LOAD_POLICY),
      policy,
      createdClaim,
    }
  }
})
